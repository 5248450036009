
import { Options, Vue } from 'vue-class-component';
import { VanClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class VanList extends Vue {

    vanList: OM.VanVm[] = [];

    created() {
        this.init();
    }

    init() {
        VanClient.getAllVans()
        .then(x => {
            this.vanList = x;
        })
    }

    editVan(van: OM.VanVm) {
        if(!van)
            this.$router.push('/fleet/van-edit/');
        else
            this.$router.push('/fleet/van-edit/' + van.vanId);
    }

}
